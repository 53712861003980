import React from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Layout from "../components/layout"
import { ReactComponent as AboutUs } from '../images/about-us.svg'

import Title from '../components/styles/Title'

export default function About({location}) {
    return (
        <Layout>
            <GatsbySeo canonical={location.href} title="About NaturalVoicer - the Best Online TTS Tool" />
            <section className="mx-auto max-w-[1000px] w-full px-4 pt-[124px] pb-20">
                <h1 css={Title("h1")} className="text-center">Who We Are</h1>
                <p className="pb-10 text-center text-gray text-lg pt-5">We provide the world's best text-to-speech online solutions.</p>
                <AboutUs className="mx-auto my-[30px] max-w-[580px] w-full"/>
                <h2 className="text-2xl font-raleway font-bold pt-[30px] pb-5">Our Mission Is Make Your Life Simpler Than Ever</h2>
                <p>We are a new team with more than 10 years of text-to-speech development experience. We have more than 50 professional engineers and marketing teams. We update our technology every day to make our users' lives easier and more interesting. </p>
                <h2 className="text-2xl font-raleway font-bold pt-[30px] pb-5">Contact Us</h2>
                <p>Tell us your issues or inquiries as <a href="mailto:support@naturalvoicer.com" className="text-blue">support@naturalvoicer.com</a>, we will get back to you in 48 hours.</p>
            </section>
        </Layout>
    )
}

